import React, { useState, useEffect } from "react"
import LocalizedLink from "./localizedLink"
import { Link } from "gatsby"
import useTranslations from "./../utils/useTranslations"
import { FaFacebook, FaTwitter, FaTelegram, FaInstagram, FaDiscord } from 'react-icons/fa';
import { IconContext } from "react-icons";
import  bittorrentLogo from "../images/logo-white.svg"
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import Flag from 'react-world-flags'
import HelpCenterLinks from "./help-center/helpCenterLinks";
import packageInfo from "../../package.json";

const version = packageInfo.version;
const Footer = ({pageContext: { footerText }}) => {
  const t = useTranslations(footerText)
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [gdprComplianceRequired, setGdprComplianceRequired] = useState(false);

  const toggle = () => setDropdownOpen(prevState => !prevState);

  useEffect(() => {
    window.didomiOnReady = window.didomiOnReady || [];
    window.didomiOnReady.push(function (Didomi) {
      setGdprComplianceRequired(Didomi.isRegulationApplied('gdpr') || Didomi.isRegulationApplied('ccpa'));
    });
    executeDidomiCMPStub();
    window.cmpScriptLoaded = true;
  }, []);

  const executeDidomiCMPStub = () => {
    if (window.cmpScriptLoaded) {
      return;
    }

    window.gdprAppliesGlobally = false;
    const tcfScript =
      '(function(){function a(e){if(!window.frames[e]){if(document.body&&document.body.firstChild){var t=document.body;var n=document.createElement("iframe");n.style.display="none";n.name=e;n.title=e;t.insertBefore(n,t.firstChild)}else{setTimeout(function(){a(e)},5)}}}function e(n,r,o,c,s){function e(e,t,n,a){if(typeof n!=="function"){return}if(!window[r]){window[r]=[]}var i=false;if(s){i=s(e,t,n)}if(!i){window[r].push({command:e,parameter:t,callback:n,version:a})}}e.stub=true;function t(a){if(!window[n]||window[n].stub!==true){return}if(!a.data){return}var i=typeof a.data==="string";var e;try{e=i?JSON.parse(a.data):a.data}catch(t){return}if(e[o]){var r=e[o];window[n](r.command,r.parameter,function(e,t){var n={};n[c]={returnValue:e,success:t,callId:r.callId};a.source.postMessage(i?JSON.stringify(n):n,"*")},r.version)}}if(typeof window[n]!=="function"){window[n]=e;if(window.addEventListener){window.addEventListener("message",t,false)}else{window.attachEvent("onmessage",t)}}}e("__tcfapi","__tcfapiBuffer","__tcfapiCall","__tcfapiReturn");a("__tcfapiLocator");(function(e){var t=document.createElement("script");t.id="spcloader";t.type="text/javascript";t.async=true;t.src="https://sdk.privacy-center.org/"+e+"/loader.js?target="+document.location.hostname;t.charset="utf-8";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n)})("23f4a63d-9222-4f37-b358-33214ecb6672")})();';
    const ccpaScript =
      '(function(){function i(e){if(!window.frames[e]){if(document.body&&document.body.firstChild){var t=document.body;var n=document.createElement("iframe");n.style.display="none";n.name=e;n.title=e;t.insertBefore(n,t.firstChild)}else{setTimeout(function(){i(e)},5)}}}function e(n,a,r,e,o){function t(e,t,n){if(typeof n!=="function"){return}if(!window[a]){window[a]=[]}var i=false;if(o){i=o(e,t,n)}if(!i){window[a].push({command:e,parameter:t,callback:n})}}t.stub=true;function i(i){if(!window[n]||window[n].stub!==true){return}if(!i.data){return}var a=typeof i.data==="string";var e;try{e=a?JSON.parse(i.data):i.data}catch(t){return}if(e[r]){var o=e[r];window[n](o.command,o.parameter,function(e,t){var n={};n.postMessageReturn={returnValue:e,success:t,callId:o.callId};i.source.postMessage(a?JSON.stringify(n):n,"*")})}}if(typeof window[n]!=="function"){window[n]=t;if(window.addEventListener){window.addEventListener("message",i,false)}else{window.attachEvent("onmessage",i)}}}e("__uspapi","__uspapiBuffer","__uspapiCall","__uspapiReturn");i("__uspapiLocator")})();';

    const scriptTag = document.createElement('script');
    scriptTag.type = 'text/javascript';
    scriptTag.setAttribute('id', 'didomi-cmp-stub');
    scriptTag.appendChild(document.createTextNode(tcfScript));
    scriptTag.appendChild(document.createTextNode(ccpaScript));
    document.head.appendChild(scriptTag);

    window.didomiConfig = {
      regulations: {
        ccpa: {
          enabled: true,
          lspa: false, // Whether your company has signed the LSPA or not (defaults to false)
        },
      },
      notice: {
        denyOptions: {
          cross: true,
        },
      },
    };
  };

  const privacyPreferencesClick = () => {
    window.Didomi.preferences.show();
  };

  return (
     <>
        <footer>      
          <div className="container pt-5 pb-3">
            <div className="row">

              <div className="col-md-2 text-center text-md-left mb-4 mb-md-0">
                <div className="mx-auto mb-1">
                  <LocalizedLink to="/" id="footer-bt-logo">
                    <img src={bittorrentLogo} style={{ height: `30px` }} alt="BitTorrent Footer Logo"/>
                  </LocalizedLink>
                </div>
              </div>            
              <div className="col-md-8 text-center text-md-left d-block d-sm-flex justify-content-sm-center justify-content-lg-around small">              
                <div className="col-5 offset-1 offset-sm-0 col-sm-3 text-left d-inline-block align-top">
                  <h6 id="company" className="">{t("Company")}</h6>
                  <ul>
                      <li>
                        <LocalizedLink to="/company/about-us/" id="footer-co-about">
                          {t("About")}
                        </LocalizedLink>
                      </li>
                      <li>
                        <LocalizedLink to="/company/contact-us/" id="footer-co-contact">
                          {t("Contact")}
                        </LocalizedLink>
                      </li>
                      <li>
                        <LocalizedLink to="/pressroom/" id="footer-co-press">
                          {t("Press")}
                        </LocalizedLink>
                      </li>
                      <li>
                        <LocalizedLink to="/company/jobs/" id="footer-co-jobs">
                          {t("Jobs")}
                        </LocalizedLink>
                      </li>
                      <li>
                        <Link to="/blog/" id="footer-co-blog">
                          {t("Blog")}
                        </Link>
                      </li>
                  </ul> 
                </div>                            
                <div className="col-5 offset-1 offset-sm-0 col-sm-3 text-left d-inline-block align-top align-top" >
                  <h6 id="downloads" className="">{t("Downloads")}</h6>
                  <ul>
                      <li>
                        <LocalizedLink to="/downloads/windows/" id="footer-dl-windows">
                          {t("Windows")}
                        </LocalizedLink>
                      </li>
                      <li>
                        <LocalizedLink to="/downloads/mac/" id="footer-dl-mac">
                          {t("Mac")}
                        </LocalizedLink>
                      </li>
                      <li>
                        <LocalizedLink to="/downloads/android/" id="footer-dl-android">
                          {t("Android")}
                        </LocalizedLink>
                      </li>
                  </ul>
                </div>                            
                <div className="col-5 offset-1 offset-sm-0 col-sm-3 text-left d-inline-block align-top">
                  <h6 id="platforms" className="">{t("Platforms")}</h6>
                  <ul>
                      <li>
                        <LocalizedLink to="/platforms/windows/" id="footer-pl-windows">
                          {t("Windows")}
                        </LocalizedLink>
                      </li>
                      <li>
                        <LocalizedLink to="/platforms/mac/" id="footer-pl-mac">
                          {t("Mac")}
                        </LocalizedLink>
                      </li>
                      <li>
                        <LocalizedLink to="/platforms/android/" id="footer-pl-android">
                          {t("Android")}
                        </LocalizedLink>
                      </li>
                  </ul>
                </div>                                   
                <div className="col-5 offset-1 offset-sm-0 col-sm-2 text-left d-inline-block align-top">
                  <h6 id="help" className="">{t("Support")}</h6>
                  <ul>
                      <li>
                        <LocalizedLink to={HelpCenterLinks.homeLink} id="footer-sup-help">
                          {t("Help")}
                        </LocalizedLink>
                      </li>
                      <li>
                        <a href="https://forum.bittorrent.com/" target="_blank" id="footer-sup-forum">
                          {t("Forum")}
                        </a>
                      </li>
                      <li>
                        <a href="https://www.surveymonkey.com/r/5CJKRDC" target="_blank" id="footer-sup-feedback">
                          {t("Site Feedback")}
                        </a>
                      </li>
                      <li>
                        <Link to={`/tip-of-the-day`} id="footer-tip-of-the-day">
                          {"Tip of the Day"}
                        </Link>
                      </li>
                  </ul> 
                </div>                
              </div>            
              <div className="col-lg-2 text-center text-md-left">
                <div className="d-flex align-items-start justify-content-center mt-4 mb-2 mb-md-0 mt-md-0">
                  <IconContext.Provider value={{ className: "socialicon" }}>
                    <a id="footer-social-fb" href="https://facebook.com/bittorrent" className="facebook footer-social-alt" target="_blank" rel="noopener noreferrer"><FaFacebook className="footer-social-icon"/>Facebook</a>
                    <a id="footer-social-twitter" href="https://twitter.com/BitTorrent" className="twitter ml-3 footer-social-alt" target="_blank" rel="noopener noreferrer"><FaTwitter className="footer-social-icon"/>Twitter</a>
                    <a id="footer-social-instagram" href="https://instagram.com/bittorrent" className="instagram ml-3 footer-social-alt" target="_blank" rel="noopener noreferrer"><FaInstagram className="footer-social-icon"/>Instagram</a>
                    <a id="footer-social-discord" href="https://discord.com/invite/pn6WWmXtQV" className="discord ml-3 footer-social-alt" target="_blank" rel="noopener noreferrer"><FaDiscord className="footer-social-icon"/>Discord</a>
                  </IconContext.Provider>
                </div>
              </div>

            </div>

            <hr style={{ margin: `2.5rem 0` , borderTop:`1px solid gray` }} />

            <div className="row mb-2 mb-sm-4">
              <div className="col-12 col-md-5 order-2 order-md-1 mt-2 mt-md-2 mb-4 mb-md-0 justify-content-center justify-content-md-start small d-flex">
                Version {version} &copy; {new Date().getFullYear()} BitTorrent Limited {t("All Rights Reserved")}.
              </div>
              <div className="col-12 col-md-7 order-1 order-md-2 mt-0 mt-md-2 mb-1 mb-md-0 text-center text-md-right small">
                <Link to="/legal/eula/" id="footer-co-eula">
                  {t("EULA")}
                </Link>
                <span className="footer-vertical-line"> | </span>
                <Link to="/legal/copyright/" id="footer-co-copyright">
                  {t("Copyright")}
                </Link>
                <span className="footer-vertical-line"> | </span>
                <Link to="/legal/terms-of-use/" id="footer-co-tos">
                  {t("termsOfUse")}
                </Link>
                <span className="footer-vertical-line"> | </span>
                <Link to="/legal/privacy-policy/" id="footer-co-privacy">
                  {t("Privacy")}
                </Link>
                {(gdprComplianceRequired && window.cmpScriptLoaded) && (
                  <>
                    <span className="footer-vertical-line"> | </span>
                    <div onClick={privacyPreferencesClick} id="footer-co-privacy-pref" className="d-inline pointer">
                      {t("Privacy Preferences")}
                    </div>
                  </>
                )}
              </div>
            </div>     
          </div>      
        </footer>
      </>
  )
}

export default Footer

