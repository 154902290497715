import CustomLayout from "./wrapPageElement"
import "./src/bootstrap.css"
// import "./src/bootstrap.min.css"
import "./src/global.css"
import "./src/templates/help-center/helpCenter.scss"
import "./src/templates/blog/blog.scss"
import "./src/transitions.css"
import "./src/font-awesome.min.css"
import locales from "./config/i18n"
import { removeTrailingSlash } from "./src/utils/gatsby-node-helpers"
import { navigate } from '@reach/router';
import { regexFirstPathElement, unlocalizedPages, getFirstPathElement } from "./src/utils/unlocalizedPageCheck"
import BENCH_CLIENT from "./src/utils/benchClient"


export const wrapPageElement = CustomLayout

const languages = ['de', 'es', 'fr', 'hi', 'it', 'ja', 'ko', 'nl', 'pl', 'pt', 'pt-br', 'ru', 'tl', 'tr', 'zh-cn', 'zh-tw']

export const onRouteUpdate = ({ location, prevLocation }) => {
  let { unlocalizedPath } = getLocaleAndUnlocalizedPath(location.pathname)

  if (unlocalizedPath === "/") {
    BENCH_CLIENT.ping('load.home')
  }
}

export const onPreRouteUpdate = (obj) => {

  // -store locale in session storage
  // when accessing unlocalized pages
  // 
  // -reapply locale
  // when leaving an unlocalized page

  let next = obj.location
  let prev = obj.prevLocation
  if(prev === null){ return }

  let nextFirstElement = getFirstPathElement(next.pathname)
  let prevFirstElement = getFirstPathElement(prev.pathname)

  if(nextFirstElement === prevFirstElement ){ return }

  if(unlocalizedPages.includes(nextFirstElement)){
    let { locale } = getLocaleAndUnlocalizedPath(prev.pathname)
    if( locale === "en" ){ return }
    sessionStorage.setItem('locale', locale)
  }
  else if(unlocalizedPages.includes(prevFirstElement)){

    let { unlocalizedPath } = getLocaleAndUnlocalizedPath(next.pathname)
    let locale = sessionStorage.getItem("locale")
    if(locale === null){
      return
    }
    let newPath = createLocalePath(locale, unlocalizedPath)
    sessionStorage.removeItem('locale')
    navigate(newPath)
  }
}

export const onClientEntry = () => {
  let ua = window.navigator.userAgent.toLowerCase()

  let isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(ua);

  let isAndroid = /(android|kindle|silk)/g.test(ua);
  let isMac = /macintosh/.test(ua);
  let isCatalina = (/mac os x 10_15/.test(ua) || /mac os x 10.15/.test(ua));
  let isLinux = !isAndroid && /linux/.test(ua);

  // Internet Explorer 6-11
  let isIE = /*@cc_on!@*/false || !!document.documentMode;

  // Edge 20+
  let isEdge = ua.indexOf("edg") > -1

  let isChrome = !!window.chrome && !isEdge;

  let isIpad = /ipad/i.test(ua) || /macintosh/i.test(ua) && navigator.maxTouchPoints && navigator.maxTouchPoints > 1;
  let isCrOS = /cros/i.test(ua);
  let isiOS = /iphone/i.test(ua);
  let isWindows = /(Windows NT)/i.test(ua)

  window.gatsbyIsMobile = isMobile 
  window.gatsbyIsAndroid = isAndroid 
  window.gatsbyIsChrome = isChrome
  window.gatsbyIsIE = isIE
  window.gatsbyIsEdge = isEdge
  window.gatsbyIsMac = isMac
  window.gatsbyIsCatalina = isCatalina
  window.gatsbyIsLinux = isLinux
  window.gatsbyIsIpad = isIpad
  window.gatsbyIsCrOS = isCrOS
  window.gatsbyIsiOS = isiOS
  window.gatsbyIsWindows = isWindows
}

function isLangMatch(localeObj, langPreferred){
  return (localeObj.locale === langPreferred || localeObj.siteLanguage === langPreferred)
}

function getLocaleAndUnlocalizedPath(pathname){
  let locale = "en"
  let unlocalizedPath = ""
  let firstPathElement = (pathname === "/") ? "en" : pathname.match(regexFirstPathElement)[1]

  if(languages.includes(firstPathElement)){
    locale = firstPathElement
    unlocalizedPath = pathname.replace(`/${locale}`, "")

  }
  else{
    unlocalizedPath = pathname
  }

  return {
    unlocalizedPath:unlocalizedPath,
    locale:locale
  }
}

function createLocalePath(locale, path){
  if(locale === "en"){
    return removeTrailingSlash(path)
  }
  else{
    let newPath = "/"+ locale + path
    return removeTrailingSlash(newPath)
  }
}




