import React from "react";
import bttLogo from "../images/components/navpopup/btt-token-logomark.svg";
import "./simplexIframeWidget.css";
import { IoMdClose } from 'react-icons/io';

class SimplexIframeWidget extends React.Component {
  render() {
    return (
      <div className="simplex-modal" style={{  visibility: this.props.isModalOpen && this.props.acceptedTerms ? 'visible' : 'hidden'}} >
        <div className="simplex-modal-content">
          <form id="simplex-form" className="d-flex flex-column">
            <button onClick={this.props.close} className="ml-auto bg-transparent border-0"><IoMdClose/></button>
            <h4 id="simplex-form-header" className="my-3 widget-header-title text-left">
              <img width="30" src={bttLogo} className="mr-2" />
              Buy BTT
            </h4>
            <div id="checkout-element" className="mb-3 w-100"></div>
          </form>
        </div>
      </div>
    );
  }
}

export default SimplexIframeWidget;
