import React from "react"
import { Link } from "gatsby"
import LocalizedLink from "./localizedLink"
import useTranslations from "../utils/useTranslations"
import NavPopup from "./navPopup"
import  NavItem2  from "./NavItem2";
import logo from "../images/logo-black.svg"
import { FaWindows } from 'react-icons/fa';
import { FaApple } from 'react-icons/fa';
import { FaAndroid } from 'react-icons/fa';
import { CompatibilityContext } from "../components/compatibilityContext";
import { removeTrailingSlash } from "../utils/gatsby-node-helpers";
import { unlocalizedPageCheck } from "../utils/unlocalizedPageCheck"
import Poloniex from "../images/components/navpopup/poloniex.png"
import krakenLogo from "../images/components/navpopup/kraken-logo.svg"
import utLogo from "../images/components/navpopup/utorrent-logo-black.svg"
import SimplexExample from "../images/simplex/simplex-example.png"
import {
  Collapse,
  Container,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown
} from 'reactstrap';
import { NavContext } from "./NavContext";
import SimplexIframeWidget from "./simplexIframeWidget"	
import { Helmet } from "react-helmet"
import HelpCenterLinks from "./help-center/helpCenterLinks";
import { getCountry } from "../utils"
import SpeedLogo from "../images/logos/speed-icon-black.svg";

const navSelections = ['Products', 'Language']
const BttNotSupportedGeos = [
  'AL', 'BA', 'HR', 'BG',
  'GR', 'XK', 'ME', 'MK',
  'RO', 'CS', 'SI', 'BY',
  'MM', 'CI', 'CU', 'CD',
  'IR', 'IQ', 'LR', 'KP',
  'SD', 'SY', 'SW', 'US',
]
const SimplexAllowedGeos = ['BR', 'IN', 'KR']

class NavbarMain extends React.Component {

  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.togglePopup = this.togglePopup.bind(this);
    this.state = {
      isOpen: false,
      isPopupOpen: false,
      selected: null,
      showMobileMenuList: [false, false],
      isMobile: false,
      isSimplexSupported: false,
      acceptedTerms: false,
      isModalOpen: false,
    };

  }

  componentDidMount(){
    this.setState({
      isMobile:window.gatsbyIsMobile,
    })
    //for the simplex widget to buy BTT
    // OFAC countries: Balkans, Belarus, Burma, Cote D'Ivoire (Ivory Coast), Cuba, Democratic Republic of Congo, Iran, Iraq, Liberia, North Korea, Sudan, Syria, and Zimbabwe
    let countryCode= this.getCountryCode(); 
    this.handleSimplexSupport(countryCode);
    // Take out Simplex
    //this.initializeSimplexIframe();
  }

  // initializeSimplexIframe(){
  //   if(typeof document !== "undefined") {
  //     var script = document.createElement('script');
  //     script.type = 'text/javascript';
  //     script.id = 'simplexFormScript'
  //     script.src = 'https://iframe.simplex-affiliates.com/form-sdk.js'
  //     script.addEventListener('load', function() {
  //       window.simplex.createForm();
  //     });
  //     document.body.appendChild(script);
  //   }   
  // }

  getCountryCode(){
    let countryCode = getCountry()
    return countryCode;
  }

  handleSimplexSupport(countryCode){
    if(SimplexAllowedGeos.includes(countryCode)){
      this.setState({
        isSimplexSupported: true,
      })
    }
  }

  checkboxHandler= (event) =>{
    event.stopPropagation();	
    this.setState({
      acceptedTerms: !this.state.acceptedTerms,
      isModalOpen: true,
    });
  }

  closeModal = (e) => {
    e.preventDefault();
    this.setState({
      isModalOpen: false,
    });
  }


  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  togglePopup(selected) {
    if(selected !== null){
      window.document.body.setAttribute("style", "overflow:hidden")
      this.setState({
        isPopupOpen: true,
        selected:selected
      });
    }
    else{
      window.document.body.setAttribute("style", "overflow:scroll")
      this.setState({
        isPopupOpen: false,
        selected:selected
      });
    }
  }

  toggleMobile = (selected) => {
    let index = navSelections.indexOf(selected)
    let mobileMenuList = this.state.showMobileMenuList
    mobileMenuList = mobileMenuList.map((item, i) => {
      return (index === i) ? !item : false
    })
    this.setState({showMobileMenuList: mobileMenuList})
  }



  //ref to control nav from page level
  toggleNav(selected) {
    return (this.state.isMobile) ? function(){this.setState({isOpen: true});this.toggleMobile(selected);}.bind(this)() : this.togglePopup(selected)
  }

  render() {
    const t = useTranslations(this.props.navText)
    return (
      <>
        { this.state.isOpen &&
          <div className="nav-mobile-bg-container" onClick={this.toggle}></div>
        }
        <Navbar light expand="md" style={{ backgroundColor: `#ffffff`, boxShadow:'0px 53px 300px -25px rgba(0,0,0,0.75)' }} >
        <Container fluid>
          <span onClick={()=>{this.togglePopup(null)}}>
            <LocalizedLink to="/" id="mainnav-bt-logo">
              <div className="navbar-brand mb-0"><img src={logo} style={{ height: `30px` }} alt="BitTorrent - The Original Torrent Client"/></div>
            </LocalizedLink>
          </span>
          <NavbarToggler onClick={this.toggle} className="ml-auto"/>
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto d-flex d-md-none nav-mobile" navbar>
              <Dropdown nav inNavbar isOpen={this.state.showMobileMenuList[0]} toggle={()=>{}} onClick={() => {this.toggleMobile(navSelections[0])}}>
                <DropdownToggle nav caret>
                  {t(`Products`)}
                </DropdownToggle>
                <DropdownMenu right>
                  <LocalizedLink to="/products/" id="mainnav-products-viewall-mobile" className="nav-mobile-link dropdown-item" onClick={this.toggle}>
                    {t(`View All`)}
                  </LocalizedLink>
                  <DropdownItem divider />
                  <h6 className="dropdown-header">{t(`Torrent Downloaders`)}</h6>
                  <DropdownItem divider />
                  <div className="position-relative" >
                    <FaWindows className="position-absolute nav-mobile-icon"/>
                    <LocalizedLink to="/products/win/bittorrent-web-free/" id="mainnav-products-btweb-windows-mobile" className="nav-mobile-link dropdown-item" onClick={this.toggle}>
                        BitTorrent Web
                    </LocalizedLink>
                  </div>
                  <div className="position-relative" >
                    <FaApple className="position-absolute nav-mobile-icon"/>
                    <LocalizedLink to="/products/mac/bittorrent-web-free/" id="mainnav-products-btweb-mac-mobile" className="nav-mobile-link dropdown-item" onClick={this.toggle}>
                      BitTorrent Web
                    </LocalizedLink>
                  </div>
                  <div className="position-relative" >
                    <FaWindows className="position-absolute nav-mobile-icon"/>
                    <LocalizedLink to="/products/win/bittorrent-classic-free/" id="mainnav-products-btclassic-windows-mobile" className="nav-mobile-link dropdown-item" onClick={this.toggle}>
                      BitTorrent Classic
                    </LocalizedLink>
                  </div>
                  <div className="position-relative" >
                    <FaAndroid className="position-absolute nav-mobile-icon"/>
                      <LocalizedLink to="/products/android/bittorrent-android-free/" id="mainnav-products-android-mobile" className="nav-mobile-link dropdown-item" onClick={this.toggle}>
                        BitTorrent Android
                      </LocalizedLink>
                  </div>
                  <div className="position-relative" >
                    <img src={SpeedLogo} className="position-absolute nav-mobile-icon" alt="" />
                    <div id="mainnav-token-btspeed-mobile" className="nav-mobile-link dropdown-item disabled">
                      BitTorrent Speed
                    </div>
                  </div>

                  <DropdownItem divider />
                  <h6 className="dropdown-header">{t(`Decentralized Storage`)}</h6>
                  <DropdownItem divider />
                  <DropdownItem >
                    <LocalizedLink to="/token/bittorrent-file-system/" id="mainnav-token-btfs-mobile" className="dropdown-item" onClick={this.toggle}>
                      BitTorrent File System
                    </LocalizedLink>
                  </DropdownItem>

                  <DropdownItem divider />
                  <h6 className="dropdown-header">{t(`Content Utility`)}</h6>
                  <DropdownItem divider />
                  <a href="https://remote.bittorrent.com" id="mainnav-products-btremote-mobile" className="nav-mobile-link dropdown-item" target="_blank" rel="noopener noreferrer" onClick={this.toggle}>
                    BitTorrent Remote
                  </a>

                  <DropdownItem divider />
                  <h6 className="dropdown-header">{t(`More Products`)}</h6>
                  <DropdownItem divider />
                  <a href="https://remote.bittorrent.com" id="mainnav-products-ut-learnmore-mobile" className="nav-mobile-link dropdown-item" target="_blank" rel="noopener noreferrer" onClick={this.toggle}>
                    <img src={utLogo} className="nav-ut-logo mobile"/>
                  </a>
                </DropdownMenu>
              </Dropdown>

              {/* <NavItem> */}
              {/*   <a href="https://now.bt.co/" id="mainnav-getcontent-mobile" className="nav-link text-decoration-none" target="_blank" rel="noopener noreferrer" onClick={this.toggle}> */}
              {/*     {t(`Get Content`)} */}
              {/*   </a> */}
              {/* </NavItem> */}

              <NavItem>
                <a href={HelpCenterLinks.homeLink} id="mainnav-help-mobile" className="nav-link text-decoration-none" rel="noopener noreferrer" onClick={this.toggle}>
                  {t(`Help`)}
                </a>
              </NavItem>

              <Dropdown nav inNavbar isOpen={this.state.showMobileMenuList[1]} toggle={()=>{}} onClick={() => {this.toggleMobile(navSelections[1])}}>
                <DropdownToggle nav caret>
                  {t(`Language`)}
                </DropdownToggle>
                <DropdownMenu right>
                  <div className="nav-mobile-languages">
                    
                    <DropdownItem onClick={this.toggle} >
                      <Link to={ this.props.unlocalizedPath } hrefLang="en" id="mainnav-lang-en-mobile" className="dropdown-item">
                        English
                      </Link>
                    </DropdownItem>
                    <DropdownItem onClick={this.toggle}>
                      <Link to={ unlocalizedPageCheck(this.props.unlocalizedPath) ? this.props.unlocalizedPath : '/zh-cn'+ this.props.unlocalizedPath } hrefLang="zh-cn" id="mainnav-lang-zh-cn-mobile" className="dropdown-item">
                        中文（简体）
                      </Link>
                    </DropdownItem>
                    <DropdownItem onClick={this.toggle}>
                      <Link to={ unlocalizedPageCheck(this.props.unlocalizedPath) ? this.props.unlocalizedPath : '/zh-tw'+ this.props.unlocalizedPath } hrefLang="zh-tw" id="mainnav-lang-zh-tw-mobile" className="dropdown-item">
                        中文（繁體）
                      </Link>
                    </DropdownItem>
                    <DropdownItem onClick={this.toggle}>
                      <Link to={ unlocalizedPageCheck(this.props.unlocalizedPath) ? this.props.unlocalizedPath : '/de'+ this.props.unlocalizedPath } hrefLang="de" id="mainnav-lang-de-mobile" className="dropdown-item">
                        Deutsch
                      </Link>
                    </DropdownItem>
                    <DropdownItem onClick={this.toggle}>
                      <Link to={ unlocalizedPageCheck(this.props.unlocalizedPath) ? this.props.unlocalizedPath : '/es'+ this.props.unlocalizedPath } hrefLang="es" id="mainnav-lang-es-mobile" className="dropdown-item">
                        Español
                      </Link>
                    </DropdownItem>
                    <DropdownItem onClick={this.toggle}>
                      <Link to={ unlocalizedPageCheck(this.props.unlocalizedPath) ? this.props.unlocalizedPath : '/fr'+ this.props.unlocalizedPath } hrefLang="fr" id="mainnav-lang-fr-mobile" className="dropdown-item">
                        Français
                      </Link>
                    </DropdownItem>
                    <DropdownItem onClick={this.toggle}>
                      <Link to={ unlocalizedPageCheck(this.props.unlocalizedPath) ? this.props.unlocalizedPath : '/hi'+ this.props.unlocalizedPath } hrefLang="hi" id="mainnav-lang-hi-mobile" className="dropdown-item">
                       हिन्दी
                      </Link>
                    </DropdownItem>
                    <DropdownItem onClick={this.toggle}>
                      <Link to={ unlocalizedPageCheck(this.props.unlocalizedPath) ? this.props.unlocalizedPath : '/it'+ this.props.unlocalizedPath } hrefLang="it" id="mainnav-lang-it-mobile" className="dropdown-item">
                        Italiano
                      </Link>
                    </DropdownItem>
                    <DropdownItem onClick={this.toggle}>
                      <Link to={ unlocalizedPageCheck(this.props.unlocalizedPath) ? this.props.unlocalizedPath : '/ja'+ this.props.unlocalizedPath } hrefLang="ja" id="mainnav-lang-ja-mobile" className="dropdown-item">
                        日本語
                      </Link>
                    </DropdownItem>
                    <DropdownItem onClick={this.toggle}>
                      <Link to={ unlocalizedPageCheck(this.props.unlocalizedPath) ? this.props.unlocalizedPath : '/ko'+ this.props.unlocalizedPath } hrefLang="ko" id="mainnav-lang-ko-mobile" className="dropdown-item">
                        한국어
                      </Link>
                    </DropdownItem>
                    <DropdownItem onClick={this.toggle}>
                      <Link to={ unlocalizedPageCheck(this.props.unlocalizedPath) ? this.props.unlocalizedPath : '/nl'+ this.props.unlocalizedPath } hrefLang="nl" id="mainnav-lang-nl-mobile" className="dropdown-item">
                        Nederlands
                      </Link>
                    </DropdownItem>
                    <DropdownItem onClick={this.toggle}>
                      <Link to={ unlocalizedPageCheck(this.props.unlocalizedPath) ? this.props.unlocalizedPath : '/pl'+ this.props.unlocalizedPath } hrefLang="pl" id="mainnav-lang-pl-mobile" className="dropdown-item">
                        Polski
                      </Link>
                    </DropdownItem>
                    <DropdownItem onClick={this.toggle}>
                      <Link to={ unlocalizedPageCheck(this.props.unlocalizedPath) ? this.props.unlocalizedPath : '/pt-br'+ this.props.unlocalizedPath } hrefLang="pt-br" id="mainnav-lang-pt-br-mobile" className="dropdown-item">
                        Português (Brasil)
                      </Link>
                    </DropdownItem>
                    <DropdownItem onClick={this.toggle}>
                      <Link to={ unlocalizedPageCheck(this.props.unlocalizedPath) ? this.props.unlocalizedPath : '/pt'+ this.props.unlocalizedPath } hrefLang="pt" id="mainnav-lang-pt-mobile" className="dropdown-item">
                        Português
                      </Link>
                    </DropdownItem>
                    <DropdownItem onClick={this.toggle}>
                      <Link to={ unlocalizedPageCheck(this.props.unlocalizedPath) ? this.props.unlocalizedPath : '/ru'+ this.props.unlocalizedPath } hrefLang="ru" id="mainnav-lang-ru-mobile" className="dropdown-item">
                        Русский
                      </Link>
                    </DropdownItem>
                    <DropdownItem onClick={this.toggle}>
                      <Link to={ unlocalizedPageCheck(this.props.unlocalizedPath) ? this.props.unlocalizedPath : '/tl'+ this.props.unlocalizedPath } hrefLang="tl" id="mainnav-lang-tl-mobile" className="dropdown-item">
                        Tagalog
                      </Link>
                    </DropdownItem>
                    <DropdownItem onClick={this.toggle}>
                      <Link to={ unlocalizedPageCheck(this.props.unlocalizedPath) ? this.props.unlocalizedPath : '/tr'+ this.props.unlocalizedPath } hrefLang="tr" id="mainnav-lang-tr-mobile" className="dropdown-item">
                        Türkçe
                      </Link>
                    </DropdownItem>
                  </div>
                </DropdownMenu>
              </Dropdown>
            </Nav>
          </Collapse>

          <NavContext.Provider value={{isPopupOpen : this.state.isPopupOpen, selected: this.state.selected}}>
         
          <div className="d-none d-md-flex ">
            <NavItem2 caret togglePopup={this.togglePopup} intName={t("Products")} name={navSelections[0]} id="mainnav-products" />
            {/* <NavItem2 name={t('Get Content')} href="https://now.bt.co/" id="mainnav-getcontent" /> */}
            <NavItem2 name={t('Help')} href={HelpCenterLinks.homeLink} id="mainnav-forum" />
            <NavItem2 caret togglePopup={this.togglePopup} intName={t(`Language`)} name={navSelections[1]} id="mainnav-language" />
          </div>
          <CompatibilityContext.Consumer>
            {(compatibilityContext) => (
            <div style={{visibility: this.state.isPopupOpen ? "visible" : "hidden"}}>	
              <NavPopup togglePopup={this.togglePopup} isMobile={this.state.isMobile} unlocalizedPath={this.props.unlocalizedPath} isSimplexSupported={this.state.isSimplexSupported} navText={this.props.navText} compatibilityContext={compatibilityContext} isModalOpen={this.state.isModalOpen} close={this.closeModal}/>	
            </div>
            )}
          </CompatibilityContext.Consumer>
          </NavContext.Provider>

        </Container>
        </Navbar>
      </>
    );
  }
}

const Navigation = React.forwardRef(({pageContext: { unlocalizedPath, navText }}, ref) => {
  const t = useTranslations(navText)
  return (    
     <NavbarMain unlocalizedPath={unlocalizedPath} navText={navText} t = {t} ref={ref}/>
  )
})

export { NavContext }

export default Navigation

