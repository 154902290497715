import React from "react";
import { IoMdClose } from 'react-icons/io';
import { CSSTransition } from 'react-transition-group' 
import { FaAndroid } from "react-icons/fa"
import { IconContext } from "react-icons";
import useTranslations from '../utils/useTranslations'
import Logo from "../components/logo"
import { getAndroidLink } from "../utils/getAndroidLink";



class AndroidBanner extends React.Component {


    render() {

		const {text} = this.props;
		const t = useTranslations(text)

    	const locale = this.props.locale
    	const badgeLink = `/images/google-play-badges/google-play-badge-${locale}.png`

    	return(
				<div className="android-banner-container">
					<div className="android-banner-small">
						<div className="row mx-0 h-100 justify-content-center flex-nowrap" style={{columnGap: '10px'}}>
							<div className="d-flex align-items-center">
				            	{t(`Get the BitTorrent App`)}
							</div>
							<div className="h-100 d-flex align-items-center">
								<a className="pointer" id="android-banner-google-play-store-btn" href={getAndroidLink('free')} target="_blank" rel="noopener noreferrer">
									<img className="d-block img-fluid" src={badgeLink} alt="BitTorrent Android Google Play" style={{width: '7.65em'}}/>
								</a>
							</div>
						</div>
						
					</div>

		        </div>
    	)
        
    }
}

export default AndroidBanner;